<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/customer"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/customer"><a>Klienci</a></router-link>
          <router-link tag="li" to="/records/customer/add" class="is-active"><a>Dodaj nowego klienta</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->

    <CustomerForm />
  </section>
</template>

<script>
  import CustomerForm from '@/views/Records/Customer/CustomerForm'
  export default {
    name: 'CustomerAdd',
    components: {
      CustomerForm
    }   
  }
</script>

<style lang="scss" scoped>

</style>